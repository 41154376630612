import React from 'react'
import '../styles/Hovercard.css'


export default function Hovercard({ name, title, job, image }) {
    return (
        <div>
            <div className="card shadow wid" >
                <div className="inner">
                    <img className="imgsrchover" src={image} alt="alt" />
                    <h2 className="textbj">{title}</h2>

                    <div className="overlaya">
                        <ul>
                            <li><h6 className="text">{name}</h6></li>
                            <li><h6 className="title">{job}</h6></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    )
}
