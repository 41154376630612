import React, { useRef } from 'react';
import '../styles/Banner.css'
export default function Banner() {


    //SCROLL TO A PERTICULAR DIV / COMPONENT
    const commentSection = useRef(null);
    const gotoCommentSection = () => window.scrollTo({
        top: commentSection.current.offsetTop,
        behavior: "smooth"
    });


    return (
        <div className="bannermaincss container">
            <div className="bannercss">
                <div className="bannerabout">
                    <p className="pbannnerbruce">Bili Web</p>
                    <h2 className="h2bnnerbruce">
                        Opportunities<br /> like never before.
                    </h2>
                </div>
                <div className="bannercontent">
                    <h5 className="h5bannerbruce">
                        Zero work experience required, we believe in your talent.
                        It's an incredible chance for college going students, we have flexible work hours,
                        get paid instantly.<br /> What are you waiting for?. <br />Earn while you learn.
                    </h5>
                </div>
            </div>
            <div className="iconscircle">
                <i className="fas fa-arrow-down iconar" onClick={gotoCommentSection}></i>
            </div>
        </div>
    )
}