import React, { useRef, useEffect } from 'react'
import '../styles/Home.css'
import '../styles/Arrowdown.css'
import logo from '../images/logo.png'
import data1 from '../images/bili1.png'
import data2 from '../images/service.png'
import data3 from '../images/tech.png'
import data5 from '../images/career.png'

import image1 from '../images/web4.png'
import image2 from '../images/web5.png'
import image3 from '../images/web6.png'
import image4 from '../images/web3.png'
import image5 from '../images/react.svg'
import image6 from '../images/python.svg'
import image7 from '../images/node.svg'
import image8 from '../images/mongo.svg'
import image9 from '../images/web.svg'
import image10 from '../images/android.svg'
import image11 from '../images/ui.svg'
import DataAnalytics from '../images/analytics.svg'
import Cardnoback from '../components/Cardnoback';
export default function Home() {
    //SCROLL TO A PERTICULAR DIV / COMPONENT
    const commentSection = useRef(null);
    const gotoCommentSection = () => window.scrollTo({
        top: commentSection.current.offsetTop,
        behavior: "smooth"
    });
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div>
            <div className="firsthomediv">
                <h1 className="hakunamata">We Deliver the world you demand</h1>
                <div className="hakunamataarrow">
                    <div className="arowed" onClick={gotoCommentSection}>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                    </div>
                </div>
            </div>
            <div className="secondhomediv" ref={commentSection}>

                <div className="gridhome">

                    <div className="gridhomepart">
                        <Cardnoback className="cardfirst" image={image1} titlefront="Lightning Fast Delivery" parafront="The team at Bili delivers your complete website at a lightning-fast speed. Unlike our competitors where you have to wait for ages to get your website. Our priority lies in serving you to the best of our abilities." />
                        <Cardnoback className="cardfirst" image={image2} titlefront="Adding speed to your business"
                            parafront="Slow hurts your bottom line. Every business wants their website to deliver great user
                          experience, regardless of their industry or niche.
                        There comes Bili" />
                        <Cardnoback className="cardfirst" image={image3} titlefront="Unbeatable price on all our services"
                            parafront="Every employee of ours strongly believes in delivering dreams 
                        at affordable prices. Times have changed. There’s no reason why launching your own
                         website should cost an arm and a leg. It’s time to change, it’s time to Bili.

                        " />
                        <Cardnoback className="cardfirst" image={image4}
                            titlefront="Customize the world you dream"
                            parafront="We enable
                         you to customize your website whenever you desire. The innovative technology help 
                         in easy customization of your pre-existing
                         website which empowers you to add inventive ideas to your business and together we grow." />
                    </div>

                    <div className="teximgalign"><img src={data1} alt="website builder" className="tagcardd" /></div>
                </div>
            </div>
            <div className="thirdhomediv">
                <div className="gridhome2">
                    <div className="teximgalign"><img src={data2} alt="website builder" className="tagcard" /></div>
                    <div className="gridhomepart2">
                        <Cardnoback className="cardsecond" image={image9} titlefront="Web Services"
                            parafront="We delivers leading Application Design, Development and Delivery services to clients." />
                        <Cardnoback className="cardsecond" image={image10} titlefront="Android and IOS Dev"
                            parafront="We delivers leading Mobile Application for Android and IOS devices." />
                        <Cardnoback className="cardsecond" image={image11} titlefront="UI/UX Design"
                            parafront="We design applications that are Useful,Usable,Empowering, and Affordable" />
                        <Cardnoback className="cardsecond" image={DataAnalytics} titlefront="Analytics"
                            parafront="Understand the behavior of your website’s visitors, gain insight into your website’s performance" />
                    </div>

                </div>
            </div>
            <div className="fourthhomediv">
                <div className="gridhome">
                    <div className="gridhomepart">
                        <Cardnoback className="cardfirst" image={image5} titlefront="React.js"
                            parafront="The website is powered by leading technology.React gives the strength to stand apart from the crowd." />
                        <Cardnoback className="cardfirst" image={image6} titlefront="Python"
                            parafront="The changing world is hungry for data and cutting edge technology just make that demand a reality." />
                        <Cardnoback className="cardfirst" image={image7} titlefront="Node.js"
                            parafront="The backend of the website must be powered by something that has no match.It's Node.js for you." />
                        <Cardnoback className="cardfirst" image={image8} titlefront="MongoDB"
                            parafront="The data of the user need to be preserved,and what could be better than this.Trusted by biggies." />
                    </div>
                    <div className="teximgalign"><img src={data3} alt="website builder" className="tagcardd" /></div>
                </div>
            </div>
            <div className="thirdhomediv">
                <div className="gridhome5">
                    <div className="teximgalign"><img src={data5} alt="website builder" className="tagcard" /></div>
                    <div className="container">
                        <div className="djasflex">                        <div>
                            <p className="lastkaamHomeKV">
                                Our core expertise involves web designing, market, internet research, company
                                research/profiling, and e-commerce solutions. Additionally, we also deliver services
                                like lead generation, data entry,
                                professional presentation, graphic designing, and transcription.
                        </p>
                            <p className="lastkaamHomeKV">Zero work experience required, we believe in your talent. It's an incredible chance for college going students, we have flexible work hours, get paid instantly.
<br />What are you waiting for?<br />
Earn while you learn.</p>
                        </div>
                        </div>

                    </div>
                    <div ><img className="logodecide" alt="seo" src={logo} /></div>

                </div>
            </div>




        </div>
    )
}
