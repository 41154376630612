import React from "react";
import "../styles/Footer.css";
import { Link } from "react-router-dom";
import { InputGroup, FormControl } from "react-bootstrap";
import logo from "../images/logo.png";

export default function Footer() {
  return (
    <div>
      <div className="footer">
        <div className="footer-content">
          <div className="coloum1">
            <h2 className="colouma">About company</h2>
            <p className="columna">
              Bili is a professional services technology company that delivers
              leading Application Design, Development and Delivery services to
              clients.
            </p>
          </div>
          <div className="coloum2">
            <h2 className="colouma">Bili</h2>
            <p>
              <Link className="linkadj" to="/about">
                About Bili
              </Link>
            </p>
            <p>
              <Link className="linkadj" to="/services">
                Services
              </Link>
            </p>
            {/* <p><Link className="linkadj" to="#"></Link></p> */}
            <p>
              <Link className="linkadj" to="/start-your-career-with-us">
                Join Team
              </Link>
            </p>
          </div>
          <div className="coloum3">
            <h2 className="colouma">CONTACT US</h2>
            <p>
              <Link className="linkadj" to="/contact">
                info@biliweb.com
              </Link>
            </p>
            <p>
              <Link className="linkadj" to="/contact">
                +91 7004788110
              </Link>
            </p>
            {/* <p><Link className="linkadj" to="#">Join Team</Link></p> */}
          </div>

          <div className="coloum5">
            <h2 className="colouma">Subscribe to newsletter</h2>
            <p className="columna">
              Sumbit Your E-mail To Receive The latest Update.
            </p>
            <form
              action="https://formsubmit.co/bilistorage01@gmail.com"
              method="POST"
            >
              <input
                type="hidden"
                name="_next"
                value="https://www.biliweb.com/"
              />
              <InputGroup className="mb-3">
                <FormControl
                  className="formcontrol"
                  placeholder="Email"
                  aria-label="Email"
                  aria-describedby="basic-addon1"
                  name="email"
                />
                <InputGroup.Prepend>
                  <InputGroup.Text className="basicadd" id="basic-addon1">
                    @
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
              <input type="hidden" name="_captcha" value="false" />
              <input type="hidden" name="_cc" value="info@biliweb.com" />
            </form>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="djflex">
            <img src={logo} alt="logo-design" className="footer-logo" />
          </div>
          <p className="columnb">
            © 2020 All Right Reserve <br /> BiliWeb is Proudly Powered by{" "}
            <span
              style={{ textDecoration: "none", color: "#000" }}
              //   href="https://bili.co.in"
            >
              Bili
            </span>
          </p>
          <div>
            <ul className="footerul">
              <li>
                <a
                  href="https://www.facebook.com/bilipvtltd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <i className="fab fa-facebook" style={{ color: "#000" }}></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/bili.pvtltd/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <i
                    className="fab fa-instagram"
                    aria-hidden="true"
                    style={{ color: "#000" }}
                  ></i>
                </a>
              </li>
              <li>
                {/* <a href="https://www.linkedin.com/in/bili-consultancy-2072021b4/" ><i className="fab fa-linkedin" aria-hidden="true" style={{ color: "#000" }}></i></a> */}
                <a
                  href="https://www.linkedin.com/company/bili-consultancy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <i
                    className="fab fa-linkedin"
                    aria-hidden="true"
                    style={{ color: "#000" }}
                  ></i>
                </a>
              </li>
              {/* <li>
                                <i className="fab fa-twitter" aria-hidden="true" style={{ color: "#000" }}></i>
                            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
