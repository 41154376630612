import React from 'react'
import { Carousel } from 'react-bootstrap'
import pic from '../images/coding2.jpg'
import pic1 from '../images/appdev.jpg'
import pic2 from '../images/coding1.jpg'
import '../styles/Carouselnocontrol.css'

export default function Carouselnocontrol() {
    return (
        <div>
            <Carousel controls={false} indicators={false} >
                <Carousel.Item>

                    <img
                        className="imgsrccaropp d-block  "
                        src={pic}
                        alt="First slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="imgsrccaropp d-block "
                        src={pic1}
                        alt="First slide"
                    />


                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="imgsrccaropp d-block "
                        src={pic2}
                        alt="First slide"
                    />

                </Carousel.Item>
            </Carousel>
        </div>
    )
}
