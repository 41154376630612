import React, { useEffect } from 'react'
import '../styles/Services.css'
import { Link } from 'react-router-dom'

import Carouselnocontrol from '../components/Carouselnocontrol'
import Highlight from '../components/Underline';
import CardService from '../components/Card';


import webDev from '../images/web.svg';
import MobApp from '../images/android.svg';
import SeoSer from '../images/seo.svg';
import UiUx from '../images/ui.svg';
import Analytics from '../images/analytics.svg';
import MachinAi from '../images/ml.svg';


export default function Services() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="pageWrapperServices">
            <div className="firstContainercontactkv">
                <div className="textofmaincontainerContactKV">
                    <p className="textContactKv" id="text1contact">  SERVICES  </p>
                    <p className="textContactKv" id="text2contact">  BRIEFING   </p>
                    <p className="linkmainContainerkv"> <Link to="/" style={{ textDecoration: "none", color: "#fff", fontWeight: "600" }}>
                        Home </Link> <span className="dotmaincontainer">.</span>Services
                           </p>
                </div>
            </div>
            {/* 1st CONTAINER ENDS */}

            <div className="secondDivServicesKV">
                <div className="grid1stContaineroneservices">
                    {/* <span className="subgeadingTopKV">  The website is made almost at no cost.</span> */}
                    <p>"We help brands and companies stand out in the digital world"</p>
                </div>
                <div className="grid2ndContaineroneservices">
                    <p className="sameonallServicesKV">
                        IT'S <span style={{ color: "#0092DB" }}> INSTANT</span>.
                        IT'S <span style={{ color: "#0092DB" }}>FAST</span>.
                        IT'S<span style={{ color: "#0092DB" }}> AFFORDABLE</span>.
                        IT'S<span style={{ color: "#0092DB" }}> BILI</span>.</p>
                    <p>
                        Every employee of ours strongly believes in delivering dreams at affordable prices.
                        Times have changed. There’s no reason why
                        launching your own website should cost an arm and a leg.
                        It’s time to change, it’s time to Bili
                    </p>
                    <div className="iconcombine">
                        <div className="iconscircleabout">
                            <i className="fas fa-arrow-right iconabout" />
                        </div>
                        <Link to="/contact" style={{ textDecoration: "none" }} className="hulkpiron"> Contact Bili</Link>
                    </div>
                </div>
            </div>
            <div className="container" id="alpha">
                <Carouselnocontrol />
            </div>

            <div className="highlightServicesKV">
                <Highlight
                    title="Highlights"
                    title2="Free Hosting"
                    subtitle="Don't pay even a penny"
                    title3="Free SSL Security"
                    subtitle1="Secure your site for ₹0"
                    title4="Integrated SEO"
                    subtitle2="No charge for being excellent"

                />
            </div>

            {/* 3RD DIV STARTS */}
            <div className="cardServicesKV">
                <div className="headingOfDivServicesKV">
                    <div className="chotaHeadingServicesKV">Your Dream World Needs A Platform</div>
                    <p className="weofferServicesKV">We offer a full range of web services at your doorstep !</p>
                </div>

                <div className="AlphaBetaKv">
                    <div className="gridForCardServicesKV">

                        <div>
                            <CardService
                                image={webDev}
                                titlefront="Web Services"
                                parafront="We delivers leading Application
                     Design, Development and Delivery services to clients.
                     "
                            />
                        </div>


                        <div>
                            <CardService
                                image={MobApp}
                                titlefront="App Development"
                                parafront="We delivers leading Mobile Application for Android 
                                and IOS devices."

                            />
                        </div>

                        <div>
                            <CardService
                                image={SeoSer}
                                titlefront="Search Engine Optimization"
                                parafront="
                               
                                We take your website to the zenith of every popular on search engine results page."
                            />
                        </div>



                        <div>
                            <CardService
                                image={UiUx}
                                titlefront="UX/UI Design"
                                parafront="We design applications that are Useful,Usable,Empowering, and Affordable"
                            />
                        </div>


                        <div>
                            <CardService
                                image={Analytics}
                                titlefront="Web Analytics"
                                parafront=" Understand 
                                the behavior of your website’s visitors, gain insight into your 
                                website’s performance."

                            />
                        </div>

                        <div>
                            <CardService
                                image={MachinAi}
                                titlefront="Machine Learning & Ai"
                                parafront="Bili translate your ideas into modern, 
                                technically robust, and well-designed digital products"

                            />
                        </div>
                    </div>
                </div>
            </div>



        </div>

    )
}
