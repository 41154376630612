import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MenuItems } from "./Menuitems";
import "../styles/Navbar.css";
import logo from "../images/logo.png";

export default class Navbar extends Component {
  state = { clicked: false, status: "top" };

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };
  componentDidMount() {
    this.listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (this.state.status !== "amir") {
          this.setState({ status: "amir" });
        }
      } else {
        if (this.state.status !== "top") {
          this.setState({ status: "top" });
        }
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener("scroll", this.listener);
  }

  render() {
    return (
      <div
        className="NavbarItemsa"
        style={{
          backgroundColor:
            this.state.status === "top" ? "transparent" : "#ffffff",
          transition: "all .6s ease",
        }}
      >
        <Link to="/">
          {" "}
          <h1
            className="navbara-logo"
            style={{ color: this.state.status === "top" ? "#fff" : "#fff" }}
          >
            <img src={logo} alt="logo-design" className="logo" />
          </h1>
        </Link>
        <div className="menua-icon" onClick={this.handleClick}>
          <i
            className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}
          ></i>
        </div>
        <ul className={this.state.clicked ? "nav-menua active" : "nav-menua"}>
          {MenuItems.map((item, index) => {
            return (
              <li key={index}>
                <div onClick={this.handleClick}>
                  <Link
                    style={{
                      color: this.state.status === "top" ? "#fff" : "#000",
                    }}
                    className={item.cName}
                    to={item.url}
                  >
                    {item.title}
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
        <ul className="nav-menua-icons">
          <li>
            <a
              href="https://www.facebook.com/bilipvtltd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i
                className="fab fa-facebook"
                style={{
                  color: this.state.status === "top" ? "#fff" : "#000",
                }}
              ></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/bili.pvtltd/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i
                className="fab fa-instagram"
                aria-hidden="true"
                style={{
                  color: this.state.status === "top" ? "#fff" : "#000",
                }}
              ></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/bili-consultancy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              <i
                className="fab fa-linkedin"
                aria-hidden="true"
                style={{
                  color: this.state.status === "top" ? "#fff" : "#000",
                }}
              ></i>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}
