import React, { useEffect } from 'react';
import '../styles/Opportunity.css';
import HoverCard from '../components/Hovercard.js';
import Carousel from '../components/Carousel';
import Banner from '../components/Banner';

import WebDev from '../images/webdev.jpg';
import UiUx from '../images/uiux.jpg';
import ContentWriting from '../images/content.jpg';
import AppDev from '../images/appdev.jpg';
import Graphics from '../images/graphics.jpg';
import Seo from '../images/seo.jpg';


import CarouselPart2 from '../components/Carouselpart2';


export default function Opportunity() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    return (
        <div>
            <div className="landingPositionCoverOpp">
                <Carousel />
            </div>
            <div className="overLayTemplateOppKV">
                <Banner />
            </div>
            <div class="mainContainerforPreKV">
                <div className="mainGridContainerOppKV">
                    <HoverCard
                        image={WebDev}
                        title="WEB DEVELOPMENT"
                        name="Frontend Designing"
                        job="Backend Development"
                    />

                    {/* 2nd card */}
                    <HoverCard
                        image={AppDev}
                        title="APP DEVELOPMENT"
                        name="Android Development"
                        job="IOS Development"
                    />
                    {/* 3rd card */}
                    <HoverCard
                        image={UiUx}
                        title="UX/UI DESIGNING"
                        name="UI/UX Designer"

                    />
                    {/* 4th card */}
                    <HoverCard
                        image={Graphics}
                        title="GRAPHICS DESIGNING"
                        name="Graphics Designer"

                    />
                    {/* 5th card */}
                    <HoverCard
                        image={Seo}
                        title="SEO"
                        name="SEARCH ENGINE OPTIMIZATION"

                    />
                    {/* 6th card */}
                    <HoverCard
                        image={ContentWriting}
                        title="CONTENT WRITING"
                        name="Content Writer"
                    />
                </div>
            </div>



            {/* PHOTO BEHIND FORM CONTAINER STARTS */}

            <div className="imagebehindforOppmKV">  </div>
            <div className="formMainGridContactKV" id="OppFormMainContainer">
                {/* column 1 starts */}
                <div className="formcolumnOfGrid" >
                    <h4 className="formheadingContactKV">TELL US ABOUT YOUR SKILLS</h4>
                    <h1 className="formheadingh1ContactKV" id="tryingOppKV">Get in touch with us</h1>

                    <form action="https://formsubmit.co/bilistorage01@gmail.com" method="POST">
                        <input type="hidden" name="_next" value="https://www.biliweb.com/" />
                        <input placeholder="Name" type="text" name="name" className="inputofFormContact" id="inputOppKV" />
                        <input placeholder="Your E-mail" type="email" name="email" className="inputofFormContact" />

                        <input placeholder="Your Mobile" type="number" name="mobile" className="inputofFormContact" />
                        <textarea name="textareaContact" className="textareaContact" rows="4"> ABOUT YOU...</textarea>
                        <div>
                            <button type="submit" className="butonOfFormContactKV">SEND MESSAGE</button>
                            <input type="hidden" name="_cc" value="info@biliweb.com" />
                            <input type="hidden" name="_captcha" value="false" />
                        </div>
                    </form>
                </div>
                {/* 2nd column */}
                <div className="mapColumnOfGrid">
                    {/* <img src={Coding} alt="web designing" id="photoOPPkv" className="officeMapContact" /> */}
                    <CarouselPart2 />
                </div>
            </div>
            <div className="container djbj">
                <h1 className="lastaboutdiv">What are you waiting for<br />Get more, pay less<br />It’s instant, it’s fast, it’s affordable<br />It’s Bili</h1>
            </div>
        </div>
    )
}
