import React from 'react'
import '../styles/Card.css'
export default function Card({ titlefront, image, parafront, parafront1 }) {
    return (
        <div>
            <div className="cardfront">
                <div className="carditem">

                    <img src={image} alt="alt" className="sccdrcimg" />
                    <h2 className="finally " >{titlefront}</h2>
                    <p className="finaly" >{parafront}<br />{parafront1}</p>

                </div>
            </div>
        </div>
    )
}