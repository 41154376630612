export const MenuItems = [
    {
        title: 'Home',
        url: '/',
        cName: 'nav-linksa'
    },
    {
        title: 'Services',
        url: '/services',
        cName: 'nav-linksa'
    },
    {
        title: 'Opportunites',
        url: '/start-your-career-with-us',
        cName: 'nav-linksa'
    },
    {
        title: 'Contact Us',
        url: '/contact',
        cName: 'nav-linksa'
    },
    {
        title: 'About Us',
        url: '/about',
        cName: 'nav-linksa'
    },
]