import React, { useEffect } from 'react';
import '../styles/Contact.css';
import { Link } from 'react-router-dom';

import Card from '../components/Cardnoback';
// import Map from '../components/Map';


import image1 from '../images/location.png';
import image2 from '../images/talk.png';
import image3 from '../images/mail.png';
import image4 from '../images/support.png';
import MapG from '../images/mapjuke.png';
import { FaFacebookSquare } from 'react-icons/fa';
import { ImLinkedin } from 'react-icons/im';
import { RiInstagramFill } from 'react-icons/ri';

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        // PARENT CONTAINER STARTS
        <div className="wrapperKV">
            {/* 1ST CONTAINER COVER STARTS */}
            <div className="firstContainercontactKV">
                <div className="textofmaincontainerContactKV">
                    <p className="textContactKv" id="text1contact">  CONTACT  </p>
                    <p className="textContactKv" id="text2contact">  BRIEFING   </p>
                    <p className="linkmainContainerkv"> <Link to="/" style={{ textDecoration: "none", color: "#fff", fontWeight: "600" }}>
                        Home </Link> <span className="dotmaincontainer">.</span>Contact
                           </p>
                </div>
            </div>
            {/* 1ST CONTAINER COVER ENDS*/}

            {/* 2ND CONTAINER HAVING LOCATION GRID STARTS */}
            <div className="gridforContactKv">
                <div className="gridnumberKv">
                    {/* 1ST CARD */}
                    <Card
                        className="cardclasschildren"
                        image={image1}
                        styletitle={{ fontSize: "16px" }}
                        styleimg={{ marginBottom: "20px", width: "50px", height: "50px" }}
                        stylepara={{ fontSize: "14px" }}
                        titlefront="MAIN OFFICE"
                        parafront="Techno Polis,Salt Lake"
                        parafront1="Sector 5,Kolkata"
                    />
                </div>
                {/* 2ND CARD */}
                <div className="gridnumberKv">
                    <Card
                        image={image2}
                        styletitle={{ fontSize: "16px" }}
                        styleimg={{ marginBottom: "20px", height: "50px" }}
                        stylepara={{ fontSize: "14px" }}
                        titlefront="LET'S TALK"
                        parafront="9142760208"
                        parafront1="(WhatsApp)"
                    />
                </div>
                {/* 3RD CARD */}
                <div className="gridnumberKv">
                    <Card
                        image={image3}
                        styletitle={{ fontSize: "16px" }}
                        styleimg={{ marginBottom: "20px", width: "72px", height: "50px" }}
                        stylepara={{ fontSize: "14px" }}
                        titlefront="E-MAIL US"
                        parafront="info@biliweb.com"
                        parafront1="techbili@bili.co.in"
                    />
                </div>
                {/* 4TH CARD */}
                <div className="gridnumberKv">
                    <Card
                        image={image4}
                        styletitle={{ fontSize: "16px" }}
                        styleimg={{ marginBottom: "20px", height: "50px", width: "68px" }}
                        stylepara={{ fontSize: "14px" }}
                        titlefront="CUSTOMER SERVICES"
                        parafront="24 X 7"
                        parafront1="It's instant,it's fast.It's Bili"
                    />
                </div>
            </div>
            {/* 2ND CONTAINER HAVING LOCATION GRID ENDS */}

            {/* PHOTO BEHIND FORM CONTAINER STARTS */}

            <div className="imagebehindformKV">  </div>

            {/* grid for contact form */}
            <div className="formMainGridContactKV">
                {/* column 1 starts */}
                <div className="formcolumnOfGrid">
                    <h4 className="formheadingContactKV">LET'S DISCUSS YOUR PROJECTS NOW</h4>
                    <h1 className="formheadingh1ContactKV">Get in touch with us</h1>

                    <form action="https://formsubmit.co/bilistorage01@gmail.com" method="POST">
                        <input type="hidden" name="_next" value="https://www.biliweb.com/" />
                        <input placeholder="Your E-mail" type="text" name="email" className="inputofFormContact" />

                        <input placeholder="Your Mobile" type="tel" name="mobile" className="inputofFormContact" />
                        <textarea name="textareaContact" className="textareaContact" rows="4"> Message...</textarea>
                        <div>
                            <button type="submit" className="butonOfFormContactKV">SEND MESSAGE</button>
                            <input type="hidden" name="_captcha" value="false" />
                            <input type="hidden" name="_cc" value="info@biliweb.com" />
                        </div>
                    </form>
                </div>
                {/* 2nd column */}
                <div className="mapColumnOfGrid">
                    <img src={MapG} alt="web designing" className="officeMapContact" />
                </div>

            </div>
            {/* LAST DIV STARTS HERE */}
            <div className="socialMediaContactKV">
                <div className="textForLastContainer">
                    Connect with social media
                    </div>
                <div className="iconForLastContainerContact">
                    <a href="https://www.facebook.com/bilipvtltd/">   <FaFacebookSquare className="iconfbliIn" id="fbicon" /></a>
                    <a href="https://www.instagram.com/bili.pvtltd/?hl=en">   <ImLinkedin className="iconfbliIn" id="linicon" /></a>
                    <a href="https://www.linkedin.com/in/bili-consultancy-2072021b4/">  <RiInstagramFill className="iconfbliIn" id="insicon" /></a>

                </div>

            </div>

        </div>


    )
}
