import React from 'react'
import '../styles/Carousel.css'
import { Carousel } from 'react-bootstrap'
import pic from '../images/opp.jpg'
import pic1 from '../images/opp1.jpg'
import pic2 from '../images/opp2.jpg'

export default function Carouseldj() {
    return (
        <div>
            <Carousel style={{ zIndex: "-1" }} indicators={false} controls={false}>
                <Carousel.Item>
                    <Carousel.Caption>
                        <h1 className="extralargebili">Earn While<br /> You Learn</h1>

                    </Carousel.Caption>
                    <img
                        className="imgsrccara d-block w-100 "
                        src={pic}
                        alt="First slide"
                    />

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="imgsrccara d-block w-100"
                        src={pic1}
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h1 className="extralargebili">Flexible<br /> Hours</h1>

                    </Carousel.Caption>

                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="imgsrccara d-block w-100"
                        src={pic2}
                        alt="First slide"
                    />  <Carousel.Caption>
                        <h1 className="extralargebili">Train<br /> Yourself</h1>

                    </Carousel.Caption>

                </Carousel.Item>
            </Carousel>
        </div>
    )
}
