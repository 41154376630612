import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import '../styles/Contact.css';
import '../styles/About.css';
import ReactPlayer from 'react-player';

import pic from '../images/SPEED.png'
import pic1 from '../images/PRICES.png'
import pic2 from '../images/BUYERS.png'
import pic3 from '../images/CUSTOMIZE.png'
import pic4 from '../images/LOADSPEED.png'
export default function About() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <div className="firstContainercontactas">
                <div className="textofmaincontainerContactKV">
                    <p className="textContactKv" id="text1contact">  ABOUT  </p>
                    <p className="textContactKv" id="text2contact">  BILI WEB  </p>
                    <p className="linkmainContainerkv"> <Link to="/" style={{ textDecoration: "none", color: "#fff", fontWeight: "600" }}>
                        Home </Link> <span className="dotmaincontainer">.</span>About
                    </p>
                </div>
            </div>
            <div className="aboutseconddiv">
                <h1 className="mainheading">Why Bili ?</h1>


                {/* <div className="cardcontainer">
                    <ul style={{ listStyle: "none" }} >
                        {Aboutmap.map((item, index) => {
                            return (
                                <li key={index}>
                                    <h5 className={item.cName} >
                                        {item.title}
                                        <div>
                                            <p className={item.cName1}>{item.para}</p>
                                            <p className={item.cName1}>{item.para2}</p>
                                        </div>
                                    </h5>
                                </li>
                            )
                        })}

                    </ul>
                </div> */}

            </div>
            <div className="afirstdiv container ">
                <div className="grid1">
                    {/* <h6 className="h6btechch">Fastest delivery of websites</h6> */}
                    <img src={pic} alt="graphics design" className="gamora" />
                    {/* <h4 className="h4btechch">
                        We help you to get your dream website at the best price in 
                     </h4> */}
                </div>
                <div className="grid2">
                    <h6 className="parafor">
                        <p className="sameonallServicesKV">
                            IT'S <span style={{ color: "#0092DB" }}> INSTANT</span>.
                        IT'S <span style={{ color: "#0092DB" }}>FAST</span>.
                        IT'S<span style={{ color: "#0092DB" }}> AFFORDABLE</span>.
                        IT'S<span style={{ color: "#0092DB" }}> BILI</span>.</p>

                    </h6>
                    <p className="columna">
                        The team at Bili delivers your complete website at
                        a lightning-fast speed. Unlike our competitors where you have to wait for
                        ages to get your website. Our priority lies in serving you to the best of
                        our abilities. We at Bili use innovative technology to keep your business
                        updated and deliver your desired site at an unmatched speed.
                       </p>
                    <div className="iconcombine">
                        <div className="iconscircleabout">
                            <i className="fas fa-arrow-right iconabout" />
                        </div>
                        <Link to="/services" className="hulkpiron" style={{ textDecoration: "none" }}>Company Services</Link>
                    </div>
                </div>

            </div>
            <div className="hulkbuster">
                <ReactPlayer url="https://youtu.be/okYb44uUsqw" className="player" width="1000px" height="500px" controls="true" />
            </div>
            <div className="afirstdiv container ">
                <div className="grid1">
                    <h6 className="paradigit">
                        Adding speed to your business
                    </h6>
                    <p className="columna">
                        Do you know a website that is too slow, results in a loss of 50% of your potential visitors?<br />
                        That’s 50% of your budget wasted! Website speed is one of the most
                        important measures of a site but is often overlooked when evaluating a website's overall performance.
                        <br />Slow hurts your bottom line. Every business wants their website to deliver great user experience,
                        regardless of their industry or niche. User experience depends on the load speed of your website.
                       </p>
                    <div className="iconcombine">
                        <div className="iconscircleabout">
                            <i className="fas fa-arrow-right iconabout" />
                        </div>
                        <Link to="/services" className="hulkpiron" style={{ textDecoration: "none" }}>Company Services</Link>
                    </div>


                </div>
                <div className="grid2">
                    <img src={pic4} alt="graphics design" className="gamora" />

                </div>
            </div>
            <div className="afirstdiv container ">
                <div className="grid1">
                    {/* <h6 className="h6btechch">Fastest delivery of websites</h6> */}
                    <img src={pic1} alt="graphics design" className="gamora" />
                    {/* <h4 className="h4btechch">
                        We help you to get your dream website at the best price in 
                     </h4> */}
                </div>
                <div className="grid2">
                    <h6 className="paradigit">
                        Unbeatable price on all our services
                    </h6>
                    <p className="columna">
                        The best services at your doorstep at a very nominal price.
                        In this post-pandemic era, affordability and efficiency become two
                        major factors for startup and business and we can proudly say that we have
                        nurtured those skills set at a very optimal level.<br /> Every employee
                        of ours strongly believes in delivering dreams at affordable prices.
                        Times have changed. There’s no reason why launching your own website should
                        cost an arm and a leg. It’s time to change, it’s time to Bili.
                       </p>
                    <div className="iconcombine">
                        <div className="iconscircleabout">
                            <i className="fas fa-arrow-right iconabout" />
                        </div>
                        <Link to="/services" className="hulkpiron" style={{ textDecoration: "none" }}>Company Services</Link>
                    </div>
                </div>
            </div>
            <div className="afirstdiv container ">
                <div className="grid1">
                    <h6 className="paradigit">
                        Brand research and strategy
                    </h6>
                    <p className="columna">
                        The people who are researching your products and services online right now is no different.
                        Today's generation has a short attention span. They don’t have the patience to sit around waiting for a
                        slow website to show up on their smartphone or desktop; they want everything immediately. The
                        longer you make them wait, the more interest they lose in you.<br />When you get to a website that takes forever to load, how does that make you feel?<br />
                         If you’re like most people then you probably experience an emotion related to anger
                          or frustration.And the website that makes you angry and fills you up with frustration do you
                           stay on that website? The answer is you will not stay on the website.Instead, you’d go to another website, right?
                       </p>
                    <div className="iconcombine">
                        <div className="iconscircleabout">
                            <i className="fas fa-arrow-right iconabout" />
                        </div>
                        <Link to="/services" className="hulkpiron" style={{ textDecoration: "none" }}>Company Services</Link>
                    </div>
                </div>

                <div className="grid2">
                    <img src={pic2} alt="graphics design" className="gamora" />

                </div>
            </div>
            <div className="afirstdiv container ">
                <div className="grid1">
                    {/* <h6 className="h6btechch">Fastest delivery of websites</h6> */}
                    <img src={pic3} alt="graphics design" className="gamora" />
                    {/* <h4 className="h4btechch">
                        We help you to get your dream website at the best price in 
                     </h4> */}
                </div>
                <div className="grid2">
                    <h6 className="paradigit">
                        Customize the world you dream
                    </h6>
                    <p className="columna">
                        The team of web developers and web designers at Bili enable you to customize your website whenever you desire.
                        The innovative technology help in easy customization of your pre-existing website which empowers you to add inventive
                        ideas to your business and together we grow.
                       </p>
                    <div className="iconcombine">
                        <div className="iconscircleabout">
                            <i className="fas fa-arrow-right iconabout" />
                        </div>
                        <Link to="/services" className="hulkpiron" style={{ textDecoration: "none" }}>Company Services</Link>
                    </div>
                </div>
            </div>



            <div className="container djbj">
                <h1 className="lastaboutdiv">What are you waiting for<br />Get more, pay less<br />It’s instant, it’s fast, it’s affordable<br />It’s Bili</h1>
            </div>
        </div>

    )
}
