import React from 'react'
import '../styles/Card.css'
export default function Cardnoback({ titlefront, className, image, parafront, parafront1, styleimg, stylepara, styletitle }) {
    return (
        <div>
            <div className={className}>
                <div className="carditem">

                    <img src={image} alt="alt" className="sccdrcimg" style={styleimg} />
                    <h2 className="finally " style={styletitle} >{titlefront}</h2>
                    <p className="finaly" style={stylepara}>{parafront}<br />{parafront1}</p>

                </div>
            </div>
        </div>
    )
}