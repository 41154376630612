import React from 'react'
import '../styles/Underline.css'

export default function Underline({ title, title2, title3, title4, subtitle, subtitle1, subtitle2 }) {
    return (
        <div>
            <div className="aboutthirddiv">
                <div className="highlight">{title}</div>
                <div className="dash1"><hr className="dash" /></div>
                <div className="paraco">{title2}<br /><p className="paracol" >{subtitle}</p></div>
                <div className="paraco">{title3}<br /><p className="paracol" >{subtitle1}</p></div>
                <div className="paraco">{title4}<br /><p className="paracol" >{subtitle2}</p></div>
            </div>
        </div>
    )
}
