import React from 'react';

import './App.css';
import Navbar from './components/Navbar';
import { Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import About from './pages/About';
import Services from './pages/Services';
import Opportunity from './pages/Opportunity';
function App() {
  return (
    <div className="App">
      <Navbar />
      <Route exact path="/" component={Home} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/about" component={About} />
      <Route exact path="/services" component={Services} />
      <Route exact path="/start-your-career-with-us" component={Opportunity} />
      <Footer />
    </div>
  );
}

export default App;
